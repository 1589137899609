.body {
  width: 100%;
  padding: 1rem;

  @media (min-width: 1024px) {
    padding: 2rem;
  }

  .body-content {
    width: 100%;

    .hero-content-mobile {
      margin-top: 5%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          color: white;
          font-size: 2.5rem;
          text-align: center;

          margin-top: 5rem;
          margin-bottom: 1rem;
        }

        p {
          text-align: center;
          opacity: 0.5;
          color: white;
          font-weight: 300;
          font-size: 1.5rem;
          line-height: 2;
          margin-top: 2rem;
        }

        button {
          margin-top: 2rem;
          width: 50%;
          height: 50px;
          background: none;
          border: 2px solid white;
          border-radius: 50px;
          color: white;
          font-weight: 300;
          transition: 0.2s;
        }

        button:hover {
          cursor: pointer;
          background: white;
          color: black;
        }

        button:focus {
          outline: none;
        }

        .hero-image {
          height: 30%;
        }
      }
    }

    .hero-content {
      width: 100%;
      margin-top: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 650px) {
        margin-top: 10%;
      }

      h1 {
        color: white;
        text-align: center;
        font-size: 7vw;
        margin-bottom: 3rem;

        @media (min-width: 650px) {
          font-size: 5vw;
          margin-bottom: 5rem;
        }

        @media (min-width: 1250px) {
          font-size: 4rem;
        }
      }

      p {
        opacity: 0.5;
        color: white;
        font-weight: 300;
        text-align: center;
        font-size: 5vw;
        line-height: 2;
        margin-top: 2rem;

        @media (min-width: 650px) {
          margin-top: 4rem;
          font-size: 3vw;
        }

        @media (min-width: 1250px) {
          font-size: 2rem;
        }
      }

      button {
        margin-top: 2rem;
        width: 80%;
        height: 50px;
        background: none;
        border: 2px solid white;
        border-radius: 50px;
        color: white;
        font-weight: 300;
      }

      button:focus {
        outline: none;
      }

      .hero-image {
        width: 100%;

        @media (min-width: 1024px) {
          height: 300px;
        }
      }
    }

    .navbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .link {
        color: white;
        text-decoration: none;
        transition: 0.2s;
      }

      .link:hover {
        cursor: pointer;
        color: gray;
      }

      .navbar-desktop {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 1020px) {
          width: 20%;
        }
      }

      .mobile-view {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 30%;
        max-width: 150px;

        @media (min-width: 500px) {
        }
      }
    }
  }
}

.hamburger-drawer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .link {
    margin-top: 2rem;
    color: black;
    text-decoration: none;
  }
}
