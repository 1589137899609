.start-views {
  padding: 1rem;
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    .sub {
      opacity: 0.7;
      line-height: 2;
    }
  }

  .part-label {
    text-align: center;
    color: white;
    font-weight: 400;
  }

  .btn-submit {
    width: 100%;
    margin-top: 2rem;
    min-height: 40px;
    border-radius: 0.2rem;
    border: none;
    outline: none;
    background: #1abd9d;
    color: white;
    transition: ease-in-out 0.2s;
  }

  .btn-submit:disabled {
    opacity: 0.4;
  }

  .btn-submit:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  hr {
    margin: 0 auto;
    width: 50%;
    margin-bottom: 2rem;
  }

  .field {
    width: 100%;
    label {
      display: block;
      color: white;
      font-weight: 300;
      opacity: 0.8;
      margin-bottom: 1rem;
    }

    .gender-select {
      width: 100%;
      height: 40px;
      border-radius: 0.3rem;
      border: none;
      outline: none;
      padding-left: 1rem;
      font-size: 0.8rem;
      background: white;
      color: black;
      transition: 0.2s;
    }

    .text-input {
      width: 100%;
      height: 40px;
      border-radius: 0.3rem;
      border: none;
      outline: none;
      padding: 1rem;
      font-size: 0.8rem;
      background: white;
      color: black;
      transition: 0.2s;
    }
  }

  .part-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;

    .fields {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    @media (min-height: 800px) {
      .fields {
        height: 800px;
        justify-content: space-evenly;
      }
    }
  }

  .part-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;

    .floating {
      width: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;

      .btn-submit {
        width: 100%;
        margin-top: 2rem;
        min-height: 40px;
        border-radius: 0.2rem;
        border: none;
        outline: none;
        background: #1abd9d;
        color: white;
        transition: ease-in-out 0.2s;
      }

      .btn-submit:disabled {
        opacity: 0.4;
      }

      .btn-submit:hover {
        cursor: pointer;
      }
    }

    .personalities {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 1rem;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: -50px;

      @media (min-width: 775px) {
        flex-direction: row;
      }

      .personality {
        width: 100%;
        margin-bottom: 2rem;

        @media (min-width: 775px) {
          width: 48%;
        }

        height: 600px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
          rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        background: white;
        padding: 1rem;
        border-radius: 0.5rem;
        justify-content: space-between;
        align-items: center;

        h5 {
          font-size: 1.5rem;
        }

        p {
          text-align: center;
          line-height: 2;
          height: 40%;
        }

        .img {
          height: 100px;
        }

        button {
          width: 100%;
          min-height: 40px;
          border-radius: 0.2rem;
          border: none;
          outline: none;
          background: #1abd9d;
          color: white;
          transition: ease-in-out 0.2s;
        }

        button:disabled {
          opacity: 0.4;
        }

        button:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .link {
      color: white;
      opacity: 0.5;
    }

    p.link {
      color: white;
    }

    .done-p {
      color: white;
      text-align: center;
      text-decoration: underline;
      transition: 0.2s;
    }

    .done-p:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    .MuiSlider-root {
      color: #1abd9d;
    }

    .change-indicator {
      text-decoration: underline;
      color: white;
      font-weight: 400;
    }

    .sketch-picker {
      margin-top: 1.5rem;
      width: 100% !important;
    }

    .fields {
      display: flex;
      flex-direction: column;

      width: 100%;

      .field {
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;
      }

      .special-field {
        display: flex;
        margin-top: 3rem;
        justify-content: space-between;
        align-items: center;

        .left {
          width: 100%;
        }
      }

      label {
        display: block;
        color: white;
        font-weight: 300;
        opacity: 0.8;
        margin-bottom: 1rem;
      }
    }
  }
}
