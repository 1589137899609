.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link {
    color: white;
    text-decoration: none;
    transition: 0.2s;
  }

  .link:hover {
    cursor: pointer;
    color: gray;
  }

  .navbar-desktop {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1020px) {
      width: 20%;
    }
  }

  .mobile-view {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 30%;
    max-width: 150px;

    @media (min-width: 500px) {
    }
  }
}
