@import "./normalize.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background: white;
}

.body-bg {
  background: #2d3f50 !important;
}
